import { toDisplayString as _toDisplayString, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "card mb-5 mb-xl-10 system-settings" }
const _hoisted_2 = { class: "card-body p-9" }
const _hoisted_3 = { class: "pb-10" }
const _hoisted_4 = { class: "w-100" }
const _hoisted_5 = { class: "pb-10 pb-lg-15 pt-10 text-center" }
const _hoisted_6 = { class: "fw-bolder text-center" }
const _hoisted_7 = { class: "text-gray-400 fw-bold fs-6" }
const _hoisted_8 = { class: "fv-row mb-8" }
const _hoisted_9 = {
  key: 0,
  class: "row"
}
const _hoisted_10 = {
  class: "btn btn-outline btn-outline-dashed btn-outline-default p-7 d-flex align-items-center",
  style: {"min-height":"122px"}
}
const _hoisted_11 = {
  key: 1,
  class: "row"
}
const _hoisted_12 = { class: "col-lg-4" }
const _hoisted_13 = { class: "col-lg-4" }
const _hoisted_14 = { class: "col-lg-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EconomicLoader = _resolveComponent("EconomicLoader")!
  const _component_IndividualCustomer = _resolveComponent("IndividualCustomer")!
  const _component_Products = _resolveComponent("Products")!
  const _component_IndividualProduct = _resolveComponent("IndividualProduct")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode("div", _hoisted_3, [
        _createVNode("div", _hoisted_4, [
          _createVNode("div", _hoisted_5, [
            _createVNode("h1", _hoisted_6, _toDisplayString(_ctx.$t('message.SAP')), 1),
            _createVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('message.HANDLE_ALL_ECONOMIC_DATA_ACCORDING_TO_COUNTRY')), 1)
          ]),
          _createVNode("div", _hoisted_8, [
            (_ctx.loader)
              ? (_openBlock(), _createBlock("div", _hoisted_9, [
                  (_openBlock(), _createBlock(_Fragment, null, _renderList(6, (i) => {
                    return _createVNode("div", {
                      class: "col-lg-4 my-4",
                      key: i
                    }, [
                      _createVNode("label", _hoisted_10, [
                        _createVNode(_component_EconomicLoader)
                      ])
                    ])
                  }), 64))
                ]))
              : (_openBlock(), _createBlock("div", _hoisted_11, [
                  _createVNode("div", _hoisted_12, [
                    _createVNode(_component_IndividualCustomer)
                  ]),
                  _createVNode("div", _hoisted_13, [
                    _createVNode(_component_Products)
                  ]),
                  _createVNode("div", _hoisted_14, [
                    _createVNode(_component_IndividualProduct)
                  ])
                ]))
          ])
        ])
      ])
    ])
  ]))
}