
import { defineComponent, ref, toRefs } from 'vue';
import Axios from '@/core/services/Http';
import { useStore } from 'vuex';
import { Notify } from '@/core/services';
import { useI18n } from 'vue-i18n';
import InnerLoader from '@/components/InnerLoader.vue';

export default defineComponent({
    name: 'languages',
    components: {
        InnerLoader
    },
    props: {},
    setup(props) {
        const store = useStore();
        const i18n = useI18n();
        const reports = ref([]) as Record<any, any>;
        const loading = ref(false);

        const getProducts = () => {
            store.getters.appInstance.$messageBox
                .confirm(`${i18n.t('message.ARE_YOU_SURE_TO_SYNC_PRODUCTS')}?`, i18n.t('message.INFO'), {
                    confirmButtonText: i18n.t('message.OK'),
                    cancelButtonText: i18n.t('message.CANCEL'),
                    type: 'info'
                })
                .then(async () => {
                    loading.value = true;
                    try {
                        const response = await Axios.get(`/sap/get-products`);
                        if (response.status == 200) {
                            Notify.success(i18n.t('message.PRODUCTS_SYNC_SUCCESSFULLY'));
                            loading.value = false;
                        }

                        // await economic_products(0);
                        loading.value = false;
                    } catch ($e) {
                        Notify.error($e);
                        loading.value = false;
                    }
                })
                .catch(() => {
                    loading.value = false;
                });
        };

        return {
            reports,
            loading,
            getProducts
        };
    }
});
