
import { defineComponent, onMounted } from 'vue';
import Sap from '../components/Sap.vue';
import { setPageHeader } from '@/core/helpers/toolbar';

export default defineComponent({
    components: {
        Sap
    },
    setup() {
        onMounted(() => {
            setPageHeader({
                title: 'message.ECONOMIC',
                actionButton: null,
                breadCrumbs: [{ name: 'message.HOME', link: '/dashboard' }, { name: 'message.ECONOMIC', link: '#' }, { name: 'message.ECONOMIC' }]
            });
        });
    }
});
